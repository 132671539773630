function _extends() {
    _extends = Object.assign || function(target) {
        for(var i = 1; i < arguments.length; i++){
            var source = arguments[i];
            for(var key in source){
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }
        return target;
    };
    return _extends.apply(this, arguments);
}
import { useAbortableEffect } from "$frontend/utils/abort";
export function useScrollRestoration(ref, key = "scroll-position") {
    useAbortableEffect((signal)=>{
        var _history_state;
        const element = ref.current;
        if (!element) return;
        addEventListener("beforepushState", (e)=>{
            history.replaceState(_extends({}, history.state, {
                [key]: element.scrollTop
            }), "");
        }, {
            signal
        });
        var _history_state_key;
        element.scrollTop = (_history_state_key = (_history_state = history.state) == null ? void 0 : _history_state[key]) != null ? _history_state_key : 0;
    });
    return ref;
}
