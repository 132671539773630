import { create } from "css-in-bun" with {
    type: "macro"
};
export default create({
    ListBody: {
        display: "grid",
        gridTemplateColumns: "var(--padding-left) 1fr var(--padding-right)",
        gap: "12px 0"
    },
    SubTabBar: {
        gridColumnStart: 1,
        gridColumnEnd: -1,
        paddingLeft: "var(--padding-left)",
        paddingRight: "var(--padding-right)",
        height: "2em",
        display: "flex",
        fontWeight: 400,
        alignItems: "center",
        position: "sticky",
        // workaround for spacing issue
        boxShadow: "0 -2px 0 var(--background-color)",
        top: "var(--header-height)",
        backgroundImage: "linear-gradient(var(--background-color) 90%, transparent)",
        zIndex: 1,
        userSelect: "none",
        WebkitUserSelect: "none"
    },
    SubTab: {
        marginLeft: "1em",
        display: "flex",
        alignItems: "center",
        position: "relative",
        whiteSpace: "nowrap",
        "::before": {
            display: "block",
            content: '""',
            height: "0.7em",
            width: "0.5px",
            borderRadius: "0.2em",
            backgroundColor: "var(--color-half)",
            transform: "translateX(-0.5em)"
        },
        ":first-child": {
            marginLeft: "0.5em",
            "::before": {
                height: "1em",
                width: "0.2em",
                backgroundColor: "var(--primary)",
                opacity: 1
            }
        }
    },
    Dot: {
        position: "absolute",
        top: -1,
        right: -1,
        width: 4,
        height: 4,
        backgroundColor: "red",
        borderRadius: 100
    },
    SubTabUnselected: {
        color: "var(--color-half)"
    },
    SubTabDisabled: {
        display: "none"
    },
    SubTitleSide: {
        flex: 1,
        marginLeft: "0.5em",
        justifySelf: "flex-end"
    },
    ItemList: {
        gridColumnStart: 2,
        display: "grid",
        gridAutoFlow: "row",
        gridTemplateColumns: "1fr 1fr",
        gridAutoRows: "max-content",
        minWidth: 0,
        gap: 20,
        paddingBottom: "2em",
        "@media screen and (min-width: 860px)": {
            gridTemplateColumns: "1fr 1fr 1fr"
        },
        "@media screen and (max-width: 540px)": {
            gap: 10,
            "@media screen and (max-width: 330px)": {
                gridTemplateColumns: "1fr"
            }
        }
    }
});
